import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Reporttable.css';

const Lottomistake = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;

    const [periods, setPeriods] = useState([]); // เก็บงวดทั้งหมด
    const [selectedPeriod, setSelectedPeriod] = useState(''); // เก็บงวดที่เลือก
    const [allReportData, setAllReportData] = useState([]); // เก็บข้อมูลทั้งหมด
    const [filteredReportData, setFilteredReportData] = useState([]); // เก็บข้อมูลที่กรอง

    const navigate = useNavigate();

    useEffect(() => {
        // ดึงข้อมูลจาก API
        axios.get(`${baseUrl}:${port}/api/lotto_mistake`)
            .then(response => {
                console.log("✅ Data fetched: ", response.data);
                setAllReportData(response.data);

                // ดึงงวดที่มีทั้งหมด (Period_no)
                const uniquePeriods = [...new Set(response.data.map(item => item.Period_no))];
                setPeriods(uniquePeriods);

                // ตั้งค่า default เป็นงวดล่าสุด
                if (uniquePeriods.length > 0) {
                    setSelectedPeriod(uniquePeriods[0]); 
                }
            })
            .catch(error => {
                console.error("❌ Error fetching data:", error);
            });
    }, []);

    // อัพเดตข้อมูลในตารางเมื่องวดที่เลือกเปลี่ยน
    useEffect(() => {
        if (selectedPeriod) {
            const groupedData = allReportData
                .filter(item => item.Period_no === selectedPeriod)
                .reduce((acc, item) => {
                    const key = `${item.username}-${item.Period_no}`;
                    
                    if (!acc[key]) {
                        acc[key] = {
                            username: item.username,
                            Period_no: item.Period_no,
                            round1: 0,
                            round2: 0
                        };
                    }

                    // นับจำนวนครั้งที่เกิดขึ้น
                    if (item.rounds === 1) {
                        acc[key].round1 += 1; // นับจำนวนครั้งที่เกิดขึ้น
                    } else if (item.rounds === 2) {
                        acc[key].round2 += 1;
                    }

                    return acc;
                }, {});

            setFilteredReportData(Object.values(groupedData));
        }
    }, [selectedPeriod, allReportData]);

    const handleCheckErrorClick = () => {
        navigate('/Reporttable');
    };

    const handleDetailsClick = () => {
        navigate('/Lottomistakedetals');
    };

    return (
        <div className="profile-container">
            <div className="profile-card">
                <div className="check-info">
                    <h1>📋ลอตเตอรี่ผิดพลาด</h1>
                    <div className="buttongroup">
                        <button className="checkbutton" onClick={handleCheckErrorClick}>
                            ตรวจทั้งหมด
                        </button>
                        <button className="detailsbutton" onClick={handleDetailsClick}>
                            รายละเอียดลอตเตอรี่ผิดพลาด
                        </button>
                        <div className="dropdown">
                            <button className="dropdown-button">{selectedPeriod}</button>
                            <div className="dropdown-content">
                                {periods.map((period, index) => (
                                    <a
                                        href="#!"
                                        key={index}
                                        onClick={() => setSelectedPeriod(period)}
                                    >
                                        {period}
                                    </a>
                                ))}
                            </div>
                        </div>

                    </div>
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>งวดที่</th>
                                <th>ผู้ตรวจ</th>
                                <th>รอบที่ 1</th>
                                <th>รอบที่ 2</th>
                                <th>รวม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReportData.length > 0 ? (
                                filteredReportData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Period_no}</td>
                                        <td>{item.username}</td>
                                        <td>{item.round1}</td>
                                        <td>{item.round2}</td>
                                        <td>{item.round1 + item.round2}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center', color: 'gray' }}>ไม่มีข้อมูล</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Lottomistake;
