import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CutHistory.css';
import { useNavigate } from 'react-router-dom';
import Notification from '../Checklotto/Notification'

const baseUrl = process.env.REACT_APP_BASE_URL;
const port = process.env.REACT_APP_PORT;

const CutHistory = () => {
    const [historyIds, setHistoryIds] = useState([]);
    const [filterHistoryId, setFilterHistoryId] = useState('');
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState({}); // เก็บสถานะของ Checkbox
    const [notificationMessage, setNotificationMessage] = useState('');
    const navigate = useNavigate();

    const History = () => navigate('/history');


    useEffect(() => {
        // ดึงค่า history_id จาก API
        axios.get(`${baseUrl}:${port}/api/cuthistorydetail`)
            .then(response => {
                setHistoryIds(response.data);
                if (response.data.length > 0) {
                    setFilterHistoryId(response.data[0]); // ตั้งค่าเริ่มต้นเป็นค่าตัวแรก
                }
            })
            .catch(error => console.error('❌ Error fetching history IDs:', error));
    }, []);

    useEffect(() => {
        if (filterHistoryId) {
            // ส่งค่าไปยังเซิร์ฟเวอร์และดึงข้อมูลกลับมา
            axios.post(`${baseUrl}:${port}/api/updatehistory`, { history_id: filterHistoryId })
                .then(response => {
                    console.log(response.data)
                    setData(response.data); // อัปเดตข้อมูลตาราง
                    setSelectedRows({}); // รีเซ็ต checkbox ทุกครั้งที่โหลดข้อมูลใหม่
                })
                .catch(error => console.error('❌ Error fetching history details:', error));
        }
    }, [filterHistoryId]);

    // ฟังก์ชันเลือก Checkbox ตั้งแต่ลำดับที่เลือกลงไป
    const handleCheckboxChange = (id) => {
        const index = data.findIndex(item => item.id === id); // หาตำแหน่งที่เลือก
        if (index === -1) return;

        const newSelectedRows = { ...selectedRows };
        for (let i = index; i < data.length; i++) {
            newSelectedRows[data[i].id] = true; // เลือกทั้งหมดตั้งแต่ตำแหน่งนี้ลงไป
        }
        setSelectedRows(newSelectedRows);
    };

    // ฟังก์ชัน Clear การเลือกทั้งหมด
    const handleClearSelection = () => {
        setSelectedRows({});
    };

    const handleCut = () => {
        const selectedData = data.filter(item => selectedRows[item.id]);

        if (selectedData.length > 0) {
            axios.post(`${baseUrl}:${port}/api/cut`, {
                filterHistoryId,
                selectedData
            })
                .then(response => {
                    console.log("✅ Server response:", response.data);
                    setNotificationMessage(response.data.message); // ตั้งค่าข้อความแจ้งเตือน
                    setSelectedRows({});
                })
                .catch(error => {
                    console.error("❌ Error sending cut request:", error);
                    setNotificationMessage("❌ Cut operation failed!");
                });
        } else {
            setNotificationMessage("⚠ Please select items to cut.");
        }
    };






    return (
        <div className="CutHistory-container">
            <h2 className="CutHistory-title">Cut History ✂️</h2>
            {/* Popup แจ้งเตือน */}
            {notificationMessage && (
                <Notification message={notificationMessage} onClose={() => setNotificationMessage('')} />
            )}
            <div className="CutHistory-filter">
                <label className="CutHistory-label">Filter by History ID:</label>
                <select
                    className="CutHistory-select"
                    value={filterHistoryId}
                    onChange={(e) => setFilterHistoryId(e.target.value)}
                >
                    {historyIds
                        .slice() // copy array เพื่อไม่ให้แก้ array ต้นฉบับ
                        .sort((a, b) => a - b) // เรียงจากน้อยไปมาก
                        .map((id) => (
                            <option key={id} value={id}>{id}</option>
                        ))}
                </select>

            </div>

            {/* ปุ่ม Clear และ Cut */}
            <div className="CutHistory-button-group">
                <button className="CutHistory-clear-button" onClick={handleClearSelection}>
                    Clear Selection
                </button>
                <button className="CutHistory-cut-button" onClick={handleCut} disabled={Object.keys(selectedRows).length === 0}>
                    Cut
                </button>

                <button className="CutHistory-cut-button" onClick={History}>
                    History
                </button>
            </div>



            <div className="CutHistory-table-container">
                <table className="CutHistory-table">
                    <thead>
                        <tr className="CutHistory-header">
                            <th className="CutHistory-th">Select</th>
                            <th className="CutHistory-th">ลำดับ</th>
                            <th className="CutHistory-th">ID</th>
                            <th className="CutHistory-th">History ID</th>
                            <th className="CutHistory-th">Order Number</th>
                            <th className="CutHistory-th">Device</th>
                            <th className="CutHistory-th">takephoto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={item.id} className="CutHistory-row">
                                <td className="CutHistory-td">
                                    <input
                                        type="checkbox"
                                        checked={!!selectedRows[item.id]}
                                        onChange={() => handleCheckboxChange(item.id)}
                                    />
                                </td>
                                <td className="CutHistory-td">{index + 1}</td>
                                <td className="CutHistory-td">{item.id}</td>
                                <td className="CutHistory-td">{item.history_id}</td>
                                <td className="CutHistory-td">{item.Order_number}</td>
                                <td className="CutHistory-td">{item.serialnumber_id}</td>
                                <td className="CutHistory-td">{item.time_takephoto}</td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};

export default CutHistory;
