import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import MainDash from './components/MainDash/MainDash';
import Sidebar from './components/LeftSide/Sidebar';
import Login from './components/Login/Login';
import Checklottery from './components/Checklotto/Checklottery';
// import Last2digits from "./components/Last2digits/Last2digits";
import OnestPrize from "./components/OnestPrize/OnestPrize";
// import Roundone from "./components/Product/Roundone";
import Round_one from "./components/Product/Round_one";
import Round_two from "./components/Product/Round_two";
import Round_three from "./components/Product/Round_three";
// import Roundtwo from "./components/Product/Roundtwo";
// import Roundthree from "./components/Product/Roundthree";
import Reporttable from "./components/Reporttable/Reporttable";
import Wrongcheck from "./components/Reporttable/Wrongcheck";
import Lottomistake from "./components/Reporttable/Lottomistake";
import Lottomistakedetals from "./components/Reporttable/Lottomistakedetals";
import Details from "./components/Reporttable/Details";
import Amindash from "./components/Amindash/Amindash";
import History from "./components/History/history";
import Cuthistory from "./components/History/cuthistory";
// import Latestnumber from "./components/History/Latestnumber";
import PrivateRoute from './components/PrivateRoute/PrivateRoute'; // นำเข้า PrivateRoute

// Layout component
const Layout = ({ children }) => (
  <>
    <Sidebar />
    {children}
  </>
);

function App() {
  return (
    <div className="App">
      <div className="AppGlass">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            {/* เส้นทางที่ต้องการให้เฉพาะ admin เข้าถึง */}
            <Route path="/1st-prize" element={<PrivateRoute element={<Layout><OnestPrize /></Layout>} isAdminOnly={true} />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Layout><Amindash /></Layout>} isAdminOnly={true} />} />

            <Route path="/Reporttable" element={<PrivateRoute element={<Layout><Reporttable /></Layout>} />} />
            <Route path="/Wrongcheck" element={<PrivateRoute element={<Layout><Wrongcheck /></Layout>} />} />
            <Route path="/Lottomistake" element={<PrivateRoute element={<Layout><Lottomistake /></Layout>} />} />
            <Route path="/Lottomistakedetals" element={<PrivateRoute element={<Layout><Lottomistakedetals /></Layout>} />} />
            <Route path="/Details" element={<PrivateRoute element={<Layout><Details /></Layout>} />} />
            <Route path="/checklottery" element={<PrivateRoute element={<Layout><Checklottery /></Layout>} />} />
            <Route path="/history" element={<PrivateRoute element={<Layout><History/></Layout>} />} />
            <Route path="/cuthistory" element={<PrivateRoute element={<Layout><Cuthistory/></Layout>} />} />
            {/* <Route path="/latestnumber" element={<PrivateRoute element={<Layout><Latestnumber/></Layout>} />} /> */}
            {/* <Route path="/Roundone" element={<PrivateRoute element={<Roundone />} />} /> */}
            <Route path="/Round_one" element={<PrivateRoute element={<Round_one />} />} />
            <Route path="/Round_two" element={<PrivateRoute element={<Round_two />} />} />
            <Route path="/Round_three" element={<PrivateRoute element={<Round_three />} />} />
            {/* <Route path="/Roundtwo" element={<PrivateRoute element={<Roundtwo />} />} /> */}
            {/* <Route path="/Roundthree" element={<PrivateRoute element={<Roundthree />} />} /> */}
          </Routes>
        </Router>
      </div>
    </div>
  );
}


export default App;
