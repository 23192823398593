import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Reporttable.css';

const Lottomistakedetals = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;
    const navigate = useNavigate();
    const [fail1Results, setFail1Results] = useState([]);
    const [periods, setPeriods] = useState([]); // ✅ เก็บ Period_no ที่ไม่ซ้ำ
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}:${port}/api/lotto_mistake/details`);
                console.log("📌 Data fetched:", response.data);

                setFail1Results(response.data || []);

                // ✅ เก็บ Period_no ที่ไม่ซ้ำ
                const uniquePeriods = [...new Set(response.data.map(row => row.Period_no))];
                setPeriods(uniquePeriods);
                if (uniquePeriods.length > 0) {
                    setSelectedPeriod(uniquePeriods[0]); // กำหนดงวดแรกเป็นค่าเริ่มต้น
                }
            } catch (error) {
                console.error("❌ Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [baseUrl, port]);

    const handleCheckErrorClick = () => {
        navigate('/Lottomistake');
    };

    const handleImageClick = (imageFileName) => {
        setSelectedImage(`${baseUrl}:${port}/images/${imageFileName}`);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    if (loading) {
        return <h2 className="loading-text">กำลังโหลดข้อมูล...</h2>;
    }

    return (
        <div className="profile-container">
            <div className="profile-card">
                <div className="check-info">
                    <h1>📋 รายละเอียดผิดพลาด</h1>
                    <div className="buttongroup">
                        <button className="check-error-button" onClick={handleCheckErrorClick}>
                            ลอตเตอรี่ผิดพลาด
                        </button>
                        <div className="dropdown">
                            <button className="dropdown-button">{selectedPeriod}</button>
                            <div className="dropdown-content">
                                {periods.map((period, index) => (
                                    <a
                                        href="#!"
                                        key={index}
                                        onClick={() => setSelectedPeriod(period)}
                                    >
                                        {period}
                                    </a>
                                ))}
                            </div>
                        </div>

                    </div>


                    <div className="styled-htable">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>งวดที่</th>
                                    <th>OrderID</th>
                                    <th>รูปภาพ</th>
                                    <th>User</th>
                                    <th>หมายเหตุ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fail1Results.length > 0 ? (
                                    fail1Results
                                        .filter(row => row.Period_no === selectedPeriod) // ✅ กรองตาม Period_no
                                        .map((row, index) => (
                                            <tr key={index} className="hover-row">
                                                <td>{row.Period_no}</td>
                                                <td>{row.id_ref}</td>
                                                <td>
                                                    <span
                                                        onClick={() => handleImageClick(row.imageFileName)}
                                                        className="view-image"
                                                    >
                                                        🔍 ดูรูป
                                                    </span>
                                                </td>
                                                <td>{row.username}</td>
                                                <td>{row.remark}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="no-data">❌ ไม่มีข้อมูล</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content">
                        <img src={selectedImage} alt="Lotto Image" className="modal-image" />
                        <button onClick={closeModal} className="close-button">❌ ปิด</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Lottomistakedetals;
