import React, { useState, useEffect } from "react";
import axios from "axios";
import "./History.css";
import { useNavigate } from 'react-router-dom';
import Notification from '../Checklotto/Notification'; // Import Notification


const baseUrl = process.env.REACT_APP_BASE_URL;
const port = process.env.REACT_APP_PORT;

const History = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, message: "" });

  useEffect(() => {
    axios.get(`${baseUrl}:${port}/api/history/latest`)
      .then((response) => {
        const formattedData = response.data.map(item => ({
          ...item,
          serialnumber_id: item.serialnumber_id || "",
          status_takephto: item.status_takephto || "",
          status_buy: item.status_buy || "",
        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching history:", error);
      });
  }, []);

  const handleCheckboxChange = (id, serialnumber_id) => {
    const key = `${id}-${serialnumber_id}`;
    setSelectedRows((prev) =>
      prev.includes(key) ? prev.filter((rowKey) => rowKey !== key) : [...prev, key]
    );
  };

  const handleSubmit = () => {
    const selectedData = selectedRows.map(rowKey => {
      const [id, serialnumber_id] = rowKey.split("-");
      const item = data.find(d => d.id === parseInt(id));
      return { id: parseInt(id), Period_no: item?.Period_no || "", serialnumber_id };
    });

    axios.post(`${baseUrl}:${port}/api/history/searchtakephoto`, selectedData)
      .then(response => {
        if (response.data.updated_status) {
          setData(prevData =>
            prevData.map(item => {
              const updatedItem = response.data.updated_status.find(u => u.id === item.id);
              return updatedItem ? { ...item, status: updatedItem.status } : item;
            })
          );
        }
        setNotification({ show: true, message: "ส่งข้อมูลสำเร็จ ✅" });
      })
      .catch(error => {
        console.error("❌ Error submitting data:", error);
        setNotification({ show: true, message: "ส่งข้อมูลไม่สำเร็จ ❌" });
      });
  };


  const Cuthistory = () => navigate('/cuthistory');

  return (
    <div className="history-container">
      <h2 className="history-title">History ⏲️</h2>
      {notification.show && ( // แสดง Notification ตรงนี้
        <Notification
          message={notification.message}
          onClose={() => setNotification({ show: false, message: "" })}
        />
      )}

      <div className="cut-history-container">
        <button className="cut-history-button" onClick={Cuthistory}>CutHistory</button>
      </div>
      <div className="history-table-container">
        <table className="history-table">
          <thead>
            <tr>
              <th>เลือก</th><th>ID</th><th>งวดที่</th><th>Sum-Number</th><th>เครื่องที่</th><th>Status Buy</th><th>Status Takephoto</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              (Array.isArray(item.serialnumber_id) ? item.serialnumber_id : [item.serialnumber_id]).map(serialnumber_id => (
                <tr key={`${item.id}-${serialnumber_id}`}>
                  <td><input type="checkbox" checked={selectedRows.includes(`${item.id}-${serialnumber_id}`)} onChange={() => handleCheckboxChange(item.id, serialnumber_id)} /></td>
                  <td>{item.id}</td>
                  <td>{item.Period_no}</td>
                  <td>{item.sum_number}</td>
                  <td>{serialnumber_id}</td>
                  <td className={item.status_buy === "succeed" ? "history-status-success" : "history-status-pending"}>{item.status_buy}</td>
                  <td className={item.status_takephto === "succeed" ? "history-status-success" : "history-status-pending"}>{item.status_takephto}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>
      <button className="history-button" onClick={handleSubmit}>ส่ง</button>
    </div>
  );
};

export default History;
